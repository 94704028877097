@use '@angular/material' as mat;
@import "./theme/l2-variables";
@import '../node_modules/ngx-heka-design-lib/styles.scss';
@import "./theme/l2-reset";

.small-info-text{
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.13px;

  p{
    margin-bottom: 0;
  }
}

.success-box{
  display: flex;
  justify-content: space-between;
  background-color: rgba(25, 135, 84, 0.1);
  border-radius: 4px;
  padding: 1rem;
}

.l2-card-input-max-width {
  max-width: 544px !important;
}

.l2-card-form-field {
  width: 100% !important;
  min-width: 250px !important;
  @extend .dense-2;
  @extend .l2-card-input-max-width;

  .mat-mdc-form-field { //dynamic
    width: 100% !important;
    max-width: 544px !important;
    min-width: 250px !important;
  }
}
